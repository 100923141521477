import {
  orderEnquiry,
  orderProduct,
  shipping,
  customerAccountBalance,
  payWay,
  enquiry,
  customerInfo,
  customerRemark,
  customerScoreRecord,
  addressList,
  customerComplain,
  salesOrder,
  followRecords,
  orderInfo,
  enquiryOrderCount,
  getCustomerListAll,
  listOperationLog,
  customerScoreRecordCount,
} from '@/core/services/api/new-customer-manage'
import i18n from '@/i18n'

// 客户登记成长说明
const scoreType = {
  1: '邮件往来',
  2: '询盘往来',
  3: '创建销售订单',
  4: '删除、作废销售订单',
  5: '部分退款',
  6: '部分退款(多付)',
  7: '全部退款',
}
// 订单记录 订单状态
const orderStatus = {
  1: '录入中',
  2: '已驳回',
  3: '已撤销',
  4: '审核中',
  5: '已通过',
  6: '待发货',
  7: '已发货',
  8: '待收货',
  9: '已收货',
  10: '已完成',
  11: '已作废',
  12: '已删除',
}
//询盘记录 询盘状态
const enquiryStatus = {
  1: '作废',
  2: '失败',
  3: '手动完成',
  4: '待报价',
  5: '已报价',
  6: '已下单',
  7: '录入中',
}
// 出运 信息 出运状态
const shipStatus = {
  9: '未出运',
  5: '已出运',
  3: '已完成',
  4: '已取消',
  0: '出库中', //
  1: '已出库', //
  2: '出库驳回', //
  6: '未出库', //
}
// 报价单 状态
const qutoStatus = {
  0: '录入中',
  1: '已报价',
  2: '已作废',
}

class companyDetailListInteractor {
  static getInstance() {
    return this._instance
  }
  // 报价单
  async getOrderEnquiry(data) {
    const { pageLe, pageNo, orderBy, infoIds, creatorCn, customerEmail } = data
    try {
      const res = await orderEnquiry({
        pageLe,
        pageNo,
        orderBy,
        infoIds,
        creatorCn,
        customerEmail,
      })
      if (res && res.code === '000000') {
        res.data.data = res.data.data.map((it) => {
          return {
            ...it,
            statusDesc: qutoStatus[it.status],
          }
        })
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 产品详情
  async getOrderProduct(data) {
    const { pageLe, pageNo, orderBy, infoIds, customerName } = data
    try {
      const res = await orderProduct({
        pageLe,
        pageNo,
        orderBy,
        infoIds,
        customerName,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 出运信息
  async getShipping(data) {
    const { pageLe, pageNo, orderBy, infoIds, businessName, customerName } =
      data
    try {
      const res = await shipping({
        pageLe,
        pageNo,
        orderBy,
        infoIds,
        businessName,
        customerName,
      })
      if (res && res.code === '000000') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data = res.data.data.map((it) => {
            return {
              ...it,
              mailSendDesc: it.mailSend == 1 ? '已发' : '未发',
              statusDesc: shipStatus[it.status] || '--',
              arriveTimeShip: it?.shipNos
                ? it.shipNos[0]?.arriveTime?.split(' ')[0]
                : '--',
              manualSignInDate: it?.manualSignInDate
                ? it?.manualSignInDate?.split(' ')[0]
                : '--',
              shippingDate: it?.shippingDate
                ? it?.shippingDate?.split('T')[0]
                : '--',
              deliveryDate: it?.deliveryDate
                ? it?.deliveryDate?.split(' ')[0]
                : '--',
              shipTime: it.shipTime ? it?.shipTime?.split(' ')?.[0] : '--',
              createTime: it.createTime
                ? it?.createTime?.split('T')?.[0]
                : '--',
            }
          })
        }
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 余额记录
  async getCustomerAccountBalance(data) {
    const { customerId, pageLe, pageNo, customerName } = data
    try {
      const res = await customerAccountBalance({
        customerId,
        pageLe,
        pageNo,
        customerName,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 支付方式
  async getPayWay(data) {
    const { pageLe, pageNo, orderBy, companyId } = data
    try {
      const res = await payWay({ pageLe, pageNo, orderBy, companyId })
      if (res && res.code === '000000') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data = res.data.data.map((it) => {
            return {
              ...it,
              // Section: `${it.beginSection},${it.endSection}`,
              Section: it.payway.split('/')[0],
              paywayDesc: it.payway.split('/')[1],
              statusDesc:
                it.payway.split('/')[1] === '100%deposit' && it.status == 2
                  ? '无需审核'
                  : it.status == 1
                  ? '审核中'
                  : it.status == 2
                  ? '审核通过'
                  : it.status == 3
                  ? '审核驳回'
                  : '--',
            }
          })
        }
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 询盘
  async getEnquiry(data) {
    const {
      pageLe,
      pageNo,
      orderBy,
      infoIdList,
      businessIdList,
      customerName,
    } = data
    try {
      const res = await enquiry({
        pageLe,
        pageNo,
        orderBy,
        infoIdList,
        businessIdList,
        customerName,
      })
      if (res && res.code === '000000') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data = res.data.data.map((it) => {
            return {
              ...it,
              enquiryStatusDesc: enquiryStatus[it.enquiryStatus],
            }
          })
        }
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 备注customerRemark
  async getCustomerRemark(data) {
    const { pageLe, pageNo, orderBy, infoId, companyId, queryType } = data
    try {
      const res = await customerRemark({
        pageLe,
        pageNo,
        orderBy,
        infoId,
        companyId,
        queryType,
      })
      if (res && res.code === '000000') {
        if (res.data.data && res.data.data.length > 0) {
          // res.data.data = res.data.data.map(it => {
          //   return {
          //     ...it,
          //     creatorName: `${it.creator}(${it.creatorEn})`
          //   }
          // })
        }
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 客户详情
  // infoId:客户id
  async getCustomerInfo(infoId) {
    try {
      const res = await customerInfo(infoId)
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 积分成长记录
  async getCustomerScoreRecord(data) {
    const { pageLe, pageNo, orderBy, companyId, infoId, linkman } = data
    try {
      const res = await customerScoreRecord({
        pageLe,
        pageNo,
        orderBy,
        companyId,
        infoId,
        linkman,
      })
      if (res && res.code === '000000') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data = res.data.data.map((it) => {
            return {
              ...it,
              scoreTypeDesc: scoreType[it.scoreType],
              score: it.score > 0 ? `+${it.score}` : it.score,
            }
          })
        }
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 收货地址

  async getAddressList(data) {
    const { pageLe, pageNo, orderBy, companyId } = data
    try {
      const res = await addressList({ pageLe, pageNo, orderBy, companyId })
      if (res && res.code === '000000') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data = res.data.data.map((it) => {
            return {
              ...it,
              statusDesc:
                it.status == 1
                  ? '审核中'
                  : it.status == 2
                  ? '审核通过'
                  : it.status == 3
                  ? '审核驳回'
                  : '--',
            }
          })
        }
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  //   评价记录
  async getCustomerComplain(data) {
    const {
      pageLe,
      pageNo,
      orderBy,
      customerIdList,
      sourceType,
      salesmanId,
      releatedCustomer,
    } = data
    try {
      const res = await customerComplain({
        pageLe,
        pageNo,
        orderBy,
        customerIdList,
        sourceType,
        salesmanId,
        releatedCustomer,
      })
      if (res && res.code === '000000') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data = res.data.data.map((it) => {
            return {
              ...it,
              // evaluationTypeDesc: it.evaluationType == 0 ? '其他反馈' : it.status == 1 ? '差评' : '--',
              // statusDesc: it.status == 1 ? '进行中' : it.status == 2 ? '完结申请审核中' : it.status == 3 ? '已完成' : it.status == 4 ? '驳回' : '--'
              evaluationTypeDesc:
                it.evaluationType == 0
                  ? i18n.t('cusManage.OtherFeedback')
                  : it.evaluationType == 1
                  ? i18n.t('cusManage.Bad')
                  : '--',
              statusDesc:
                it.status == 1
                  ? i18n.t('cusManage.Ongoing')
                  : it.status == 2
                  ? i18n.t('cusManage.ClosingApplicationUnderReview')
                  : it.status == 3
                  ? i18n.t('cusManage.Done')
                  : it.status == 4
                  ? i18n.t('cusManage.Reject')
                  : '--',
            }
          })
        }
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  //   订单记录
  async getSalesOrder(data) {
    const {
      pageLe,
      pageNo,
      orderBy,
      infoIdList,
      businessIdList,
      customerName,
    } = data
    try {
      const res = await salesOrder({
        pageLe,
        pageNo,
        orderBy,
        infoIdList,
        businessIdList,
        customerName,
      })
      if (res && res.code === '000000') {
        res.data.data = res.data.data.map((it) => {
          return {
            ...it,
            orderStatusDesc: orderStatus[it.orderStatus],
          }
        })
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  //   跟进记录
  async getFollowRecords(data) {
    const { pageLe, pageNo, orderBy, infoIds, linkman, startTime, endTime } =
      data
    try {
      const res = await followRecords({
        pageLe,
        pageNo,
        orderBy,
        infoIds,
        linkman,
        startTime,
        endTime,
      })
      if (res && res.code === '000000') {
        // res.data.data = res.data.data.map(it => {
        //   return {
        //     ...it,
        //     evaluationTypeDesc: it.evaluationType == 0 ? '其他反馈' : it.status == 1 ? '差评' : '--',
        //     statusDesc: it.status == 1 ? '进行中' : it.status == 2 ? '完结申请审核中' : it.status == 3 ? '已完成' : it.status == 4 ? '驳回' : '--'
        //   }
        // })
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 订单详情orderInfo
  async getOrderInfo(data) {
    const { infoIdList, businessIdList, customerName } = data
    try {
      const res = await orderInfo({ infoIdList, businessIdList, customerName })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 询盘统计
  async getEnquiryOrderCount(data) {
    const { infoIdList, businessIdList, customerName } = data
    try {
      const res = await enquiryOrderCount({
        infoIdList,
        businessIdList,
        customerName,
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 客户信息
  async getCustomerListAll(data) {
    const { pageLe, pageNo, orderBy, infoIds, companyId, keyIds } = data
    try {
      const res = await getCustomerListAll({
        pageLe,
        pageNo,
        orderBy,
        infoIds,
        companyId,
        keyIds,
      })
      if (res && res.code === '000000') {
        res.data.data = res.data.data.map((it) => {
          return {
            ...it,
            // tagDesc: it.tag == 1 ? '有意向客户' : it.tag == 2 ? '待跟进客户' : it.tag == 3 ? '下单未签收客户' : it.tag == 4 ? '成交客户' : it.tag == 5 ? '重点客户' : '--',
            tagDesc:
              it.tag == 1
                ? i18n.t('cusManage.InterestedCustomers')
                : it.tag == 2
                ? i18n.t('cusManage.CustomersToBeFollowedUp')
                : it.tag == 3
                ? i18n.t('cusManage.UnsignedCustomer')
                : it.tag == 4
                ? i18n.t('cusManage.TransactionCustomers')
                : it.tag == 5
                ? i18n.t('cusManage.KeyCustomers')
                : '--',
            // customerTypeDesc: it.customerType == 2 ? '黑名单' : it.customerType == 1 ? '正式客户' : it.customerType == 0 ? '潜在客户' : '--',
            customerTypeDesc:
              it.customerType == 2
                ? i18n.t('cusManage.Blacklist')
                : it.customerType == 1
                ? i18n.t('cusManage.OfficialCustomer')
                : it.customerType == 0
                ? i18n.t('cusManage.PotentialCustomers')
                : '--',
            // statusDesc: it.status == 1 ? '已完成' : it.status == 2 ? '修改审核中' : it.status == 3 ? '转入黑名单审核中' : it.status == 4 ? '审核驳回' : '--'
            statusDesc:
              it.status == 1
                ? i18n.t('cusManage.Finished')
                : it.status == 2
                ? i18n.t('cusManage.Modifying')
                : it.status == 3
                ? i18n.t('cusManage.TransferredToBlacklistReview')
                : it.status == 4
                ? i18n.t('cusManage.ApprovalRejection')
                : '--',
            systemRemarkDesc: `${i18n.t('cusManage.systemRemarkTips1')} ${
              it.systemRemark
            } ${i18n.t('cusManage.systemRemarkTips2')}`,
          }
        })
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  //  操作日志
  async getListOperationLog(data) {
    const { pageLe, pageNo, orderBy, infoIds, companyId } = data
    try {
      const res = await listOperationLog({
        pageLe,
        pageNo,
        orderBy,
        infoIds,
        companyId,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 客户等级成长记录-积分总值
  customerScoreRecordCountApi({ companyId, infoIds }) {
    return customerScoreRecordCount({ companyId, infoIds })
  }
}

companyDetailListInteractor._instance = new companyDetailListInteractor()

const CompanyDetailListInteractor = companyDetailListInteractor.getInstance()
export default CompanyDetailListInteractor
