<template>
  <el-dialog
    :title="lang === 'en' ? 'Select personnel' : '选择人员'"
    :visible.sync="dialogFormVisible"
    width="50%"
    :close-on-click-modal="false"
    append-to-body
    @close="close"
  >
    <div class="dialog-content mb20">
      <el-row class="search" :gutter="20">
        <el-col :span="8">
          <el-input
            v-model="queryForm.cusName"
            :placeholder="lang === 'en' ? 'please input' : '请输入'"
            clearable
            @keyup.enter.native="searchClick"
            @input="searchInput"
          />
        </el-col>
        <el-button class="pub-h-36" @click="searchClick">
          {{ lang === 'en' ? 'Search' : '搜索' }}
        </el-button>
      </el-row>
      <p class="single-select-tip red">
        <span v-if="singleSelect">仅能选择一个人员</span>
      </p>
      <el-row :gutter="40" class="tree-box" style="margin-left: 0">
        <el-col :span="14" style="overflow-y: auto">
          <el-tree
            ref="tree"
            :data="treeData"
            show-checkbox
            :default-expand-all="true"
            node-key="id"
            highlight-current
            :props="defaultProps"
            :check-strictly="singleSelect"
            :default-checked-keys="checkedId"
            @check="(node, data) => checkChange(node, data, 1)"
            @node-click="(node, data) => checkChange(node, data, 2)"
          >
            <el-row
              slot-scope="{ data }"
              type="flex"
              align="middle"
              class="custom-tree-node w100"
            >
              <!-- <el-avatar v-if="data.type === 1" size="small" :src="circleUrl" /> -->
              <span v-if="data.type === 1" class="iconfont icon-person"></span>
              <!-- <i
                v-if="data.type === 0"
                class="el-icon-s-data"
                style="font-size: 18px"
              ></i>-->
              <span v-if="data.type === 0" class="iconfont icon-bumen"></span>
              <span v-if="lang !== 'en'" class="text-hidden ml10">
                {{ data.name }}
                <i
                  v-if="data.type === 1 && data.englishName"
                  style="font-style: normal; color: #9e9e9e"
                >
                  ({{ data.englishName }})
                </i>
              </span>
              <span v-else>{{ data.englishName || data.name }}</span>
            </el-row>
          </el-tree>
        </el-col>
        <el-col
          :span="10"
          style="border-left: 1px solid #ededed; overflow-y: auto"
        >
          <el-row v-if="checkedUser.length">
            <el-row class="mb-10">
              <div
                style="
                  display: flex;
                  align-item: center;
                  justify-content: space-between;
                "
              >
                <h4>
                  {{ lang === 'en' ? 'selected' : '已选' }}
                  {{ checkedUser.length }} {{ lang === 'en' ? 'people' : '人' }}
                  <span
                    v-if="currentStr === 'addAuditManage'"
                    class="mt5 f_s_12 span-tip red"
                  >
                    tip：{{
                      lang === 'en'
                        ? 'Audit managers can choose a maximum of three'
                        : '审核管理者最多选择三个'
                    }}
                  </span>
                </h4>
                <div v-if="!singleSelect" class="delete-all" @click="delAll">
                  删除全部
                </div>
              </div>
            </el-row>
            <el-row
              v-for="(item, index) of checkedUser"
              :key="index"
              class="check-user"
              type="flex"
              align="middle"
            >
              <el-col :span="22">
                <el-row type="flex" align="middle">
                  <!-- <el-avatar size="small" :src="circleUrl" /> -->
                  <span class="iconfont icon-person"></span>
                  <p>{{ lang !== 'en' ? item.name : item.englishName }}</p>
                </el-row>
              </el-col>
              <el-col :span="2">
                <i
                  class="el-icon-close c_pointer"
                  @click="delClick(item, index)"
                ></i>
              </el-col>
            </el-row>
          </el-row>
          <el-row v-else class="t_a_c">
            {{
              lang === 'en'
                ? 'No one has been chosen yet...'
                : '还没有选择人员...'
            }}
          </el-row>
        </el-col>
      </el-row>
      <!-- 转发相关邮件 -->
      <el-row v-if="currentStr === 'shipingOrderInquiry'">
        <el-checkbox v-model="emailChecked" class="mt10">
          {{
            lang === 'en'
              ? 'Select the message to forward to the person'
              : '选择邮件转发给该人员'
          }}
        </el-checkbox>
      </el-row>
    </div>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" @click="save">
          {{ lang === 'en' ? 'Confirm' : '确定' }}
        </el-button>
        <el-button @click="close">
          {{ lang === 'en' ? 'Cancel' : '取消' }}
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { deptTreeWithUser } from '@/api/organization-manage'
  import { listUserRoleNoPage } from '@/api/power-group'
  import { mapGetters } from 'vuex'
  import checkChange from '@/utils/tree-check-change.js'
  export default {
    name: 'PowerGroupAddModel',
    props: {
      filters: {
        type: Object,
        default() {
          return {}
        },
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        name: '',
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'label',
          disabled: null,
        },
        checkedUser: [],
        checkedId: [],
        queryForm: {
          cusName: '',
        },
        circleUrl:
          'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        roleId: '',
        currentStr: '', //重要-判断调用组织树的目的
        optionalType: null, //自选单个还是多个
        singleSelect: false, // 是否为单选，默认为多选
        checkedUser2: [],
        emailChecked: false, //显示选择邮件转发
        // lang: 'zh',
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
    },
    created() {},
    mounted() {
      // this.getLang()
    },
    methods: {
      checkChange,
      // getLang() {
      // let language = localStorage.getItem('language')
      // if (JSON.parse(language)) {
      //   this.lang = JSON.parse(language).language
      // }
      // },
      /**
       * 新增、编辑
       * @param  {[type]} str          [参数用来判断你是调用组织树的目的]
       * @param  {[type]} roleId       [可以用于系统管理权限分组，也可以用于其他人员选择]
       * @param  {[type]} singleSelect [是否为单选，默认为多选]
       */
      showAddEdit(str, roleId, singleSelect) {
        this.singleSelect = singleSelect || false
        if (singleSelect) {
          this.defaultProps.disabled = (node, data) => {
            return node.type === 0
          }
        }
        if (Array.isArray(roleId)) {
          this.checkedId = roleId
        } else if (roleId) {
          this.roleId = roleId
        }
        this.currentStr = str

        this.getDeptTreeWithUser()
      },

      //搜索
      searchClick() {
        this.getDeptTreeWithUser()
      },
      //清空搜索
      searchInput(val) {
        if (val === '') {
          //this.$refs.tree.setCheckedKeys([])
          this.getDeptTreeWithUser()
        }
      },

      //递归树
      recursionTree(tree, arrId) {
        tree.forEach((item) => {
          arrId.forEach((item2) => {
            if (item.id == item2) {
              this.checkedUser2.push(item)
            }
          })
          if (item.children && item.children.length) {
            this.recursionTree(item.children, arrId)
          }
        })
        return this.checkedUser2
      },

      //部门及成员
      async getDeptTreeWithUser(optionalType, noChooseUser) {
        if (optionalType) {
          this.optionalType = optionalType
        }
        let response = await deptTreeWithUser({
          ...this.queryForm,
          ...this.filters,
        })
        if (response.code === '000000') {
          this.treeData = response.data
          this.dialogFormVisible = true
          if (this.treeData?.length) {
            this.checkedUser2 = []
            let checkedUser = this.recursionTree(this.treeData, this.checkedId)
            this.checkedUser = this.unique(this.checkedUser.concat(checkedUser))
          }

          //审核模板中已选中的人员，置灰
          if (noChooseUser) {
            this.getCheckIdsAll(this.treeData, noChooseUser)
          }
          //如果有角色roleId，需要调用当前角色下面的所有成员接口，用于回显
          if (this.roleId) {
            this.getListUserRoleNoPage()
          }
          this.$forceUpdate()
        }
      },

      //审核模板-置灰已选择人员
      getCheckIdsAll(arr, val) {
        if (arr) {
          arr.forEach((item, index) => {
            if (val.includes(item.id)) {
              this.$set(item, 'disabled', true)
              if (item.children) {
                this.getCheckIdsAll(item.children, val)
              }
            } else {
              this.$set(item, 'disabled', false)
              if (item.children) {
                this.getCheckIdsAll(item.children, val)
              }
            }
          })
        }
      },

      //获取当前角色已选中成员
      async getListUserRoleNoPage() {
        this.checkedUser = []
        this.checkedId = []
        let response = await listUserRoleNoPage({ roleId: this.roleId })
        if (response.code === '000000') {
          this.checkedUser = response.data
          this.checkedId = this.checkedUser.map((item) => {
            return item.id
          })
        }
      },

      unique(arr) {
        const res = new Map()
        return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
      },

      //删除（取消选中）
      delClick(item, index) {
        if (
          item.id === this.userInfo.userId &&
          this.currentStr === 'addAuditManage' // 出运委托单业务员选择可删除登录人
        ) {
          const msg =
            this.lang === 'en'
              ? 'The current logon cannot be deleted'
              : '当前登录人不能删除'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.checkedUser.splice(index, 1)
        this.checkedId = this.checkedId.filter((id) => id !== item.id)
        this.$refs.tree.setChecked(item.id, false, true)
      },

      delAll() {
        this.checkedUser = []
        this.checkedId = []
        this.$refs.tree.setCheckedKeys([])
      },

      close() {
        this.checkedUser = []
        this.treeData = []
        this.checkedId = []
        this.optionalType = null
        this.roleId = ''
        this.currentStr = ''
        this.emailChecked = false
        this.queryForm.cusName = ''
        this.dialogFormVisible = false
      },
      async save() {
        //如果是审核流程管理者添加，最多选择两个(加上当前登录者共三个)
        if (
          this.checkedUser.length > 3 &&
          this.currentStr === 'addAuditManage'
        ) {
          const msg =
            this.lang === 'en'
              ? 'The audit process manager can select a maximum of three'
              : '审核流程管理者最多选择三个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        if (this.checkedUser.length > 1 && this.currentStr === 'belongerUser') {
          const msg =
            this.lang === 'en'
              ? 'The transferee of escheatage can choose one at most'
              : '归属权转让人最多选择一个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        //shipingOrder
        if (
          this.checkedUser.length > 1 &&
          (this.currentStr === 'shipingOrder' ||
            this.currentStr === 'shipingOrderInquiry')
        ) {
          const msg =
            this.lang === 'en'
              ? 'A salesman can select at most one'
              : '业务员最多选择一个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        if (this.optionalType === 0 && this.checkedUser.length > 1) {
          const msg =
            this.lang === 'en'
              ? 'A maximum of one person can be selected'
              : '人员最多选择一个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        if (this.singleSelect && this.checkedUser.length > 1) {
          const msg =
            this.lang === 'en'
              ? 'A maximum of one person can be selected'
              : '人员最多选择一个'
          return this.$baseMessage(
            msg,
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }

        if (this.checkedUser.length) {
          if (this.currentStr === 'shipingOrderInquiry') {
            this.$emit('choose-user', this.checkedUser, this.emailChecked)
          } else {
            this.$emit('choose-user', this.checkedUser, this.emailChecked)
          }

          this.close()
        } else {
          //搜索人员组织结构树可以为空
          if (this.currentStr !== 'listSearch') {
            const msg =
              this.lang === 'en'
                ? 'Please select the person you want to add'
                : '请勾选要添加的人员'
            this.$baseMessage(msg, 'warning', false, 'erp-hey-message-warning')
          } else {
            this.$emit('choose-user', this.checkedUser)
            this.close()
          }
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .dialog-content {
    height: 500px;

    .el-form {
      width: 95%;
      margin: 0 auto;
    }

    .tree-box {
      width: 100%;
      height: calc(100% - 62px);
      border: 1px solid #ededed;
      padding: 20px;

      .el-col {
        height: 100%;
      }
    }
  }

  .span-tip {
    font-weight: normal;
  }

  .check-user {
    border-bottom: 1px solid #ededed;
    padding-bottom: 15px;
    margin-top: 15px;

    p {
      margin-left: 10px;
    }
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }

    .el-tree-node__content {
      height: 35px;
      line-height: 35px;
    }
  }

  .delete-all {
    line-height: 21px;
    padding: 4px 12px;
    cursor: pointer;
    font-size: 12px;
    border-radius: 5px;
    color: #fff;
    background-color: #606266;
  }

  .single-select-tip {
    height: 32px;
    line-height: 32px;
    padding-left: 8px;
    font-size: 14px;
  }
</style>
